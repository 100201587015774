/* Existing styles */
.esg-custom-header {
  font-weight: bold;
}

.esg-source-table {
  font-size: 12px;
  color: gray;
}

.cell-green {
  background-color: #d4edda;
}

.cell-lightgreen {
  background-color: #d1e7dd;
}

.cell-yellow {
  background-color: #fff3cd;
}

.cell-orange {
  background-color: #f8d7da;
}

.cell-red {
  background-color: #f5c6cb;
}

.esgmaintable-custom-font .ant-table-tbody > tr > td {
  font-size: 14px;
}

.ant-collapse-header {
  font-weight: bold;
}

#ESG_Details-content {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Add some space between the top and bottom sections */
}

.top-tables {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Add space between the two top tables */
}

.bottom-tables {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Add space between the three bottom tables */
}

.table-wrapper {
  flex: 1;
  min-width: 200px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  background-color: #f9f9f9; /* Light background color for each table */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for a 3D effect */
}

.table-wrapper h3 {
  text-align: center;
  margin-bottom: 10px;
}

/* New styles for the ESG_Input component */
.esg-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.esg-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.esg-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.company-search,
.etf-search,
.dropzone {
  padding: 20px;
  margin: 10px;
  box-sizing: border-box;
}

.company-search {
  background-color: rgba(135, 206, 250, 0.5); /* Light blue background */
}

.etf-search {
  background-color: rgba(70, 130, 180, 0.5); /* Steel blue background */
}

.dropzone {
  border: 1px dashed gray;
  background-color: rgba(173, 216, 230, 0.5); /* Light blue shade */
}

.is-loading {
  margin-top: 60px;
  margin-bottom: 60px;
  text-align: center;
}

.file-analyzing-message {
  margin-top: 20px;
}

.section-header {
  font-weight: bold;
  text-align: center;
}

/* New styles for horizontal layout */
.esg-column-horizontal {
  display: flex;
  align-items: center; /* Align items vertically centered */
}

.company-info {
  margin-left: 20px; /* Add space between the search and text */
  font-weight: bold;
}

.portfolio-info {
  margin-left: 20px; /* Add space between the search and text */
  font-weight: bold;
}

.etf-info {
  margin-right: 20px; /* Add space between the text and ETF search box */
  font-weight: bold;
}
.show-example {
  display: block;
  margin-top: 10px;
  color: #1890ff;
  text-decoration: underline;
  cursor: pointer;
}
.show-example:hover {
  color: #40a9ff;
}


