.dalle20240809124436A {
    width: 1494px;
    height: 847px;
    position: absolute;
    margin: 0 !important;
    right: -583px;
    bottom: -390px;
    border-radius: 274px;
    object-fit: cover;
  }
  .forYourConscious {
    position: relative;
    font-weight: 800;
    z-index: 1;
  }
  .forYourConsciousInvestmentWrapper {
    width: 681px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    max-width: 100%;
  }
  .doYouValueSociallyResponsi {
    margin: 0;
    font-size: var(--font-size-base);
  }
  .andWhatAbout,
  .blankLine,
  .blankLine1,
  .blankLine2,
  .blankLine3,
  .blankLine4,
  .blankLine5,
  .blankLine6,
  .butDidYou,
  .howDoThese,
  .ifYouAlready,
  .itsFreeEasy,
  .ourEsgCheckTool,
  .weDoToo,
  .youveProbablyHeard {
    margin: 0;
  }
  .bondBot,
  .doYouValueContainer {
    position: relative;
    text-align: left;
    color: var(--color-black);
    font-family: var(--font-inter);
  }
  .doYouValueContainer {
    display: inline-block;
    max-width: 100%;
    z-index: 1;
    font-size: var(--font-size-sm);
  }
  .bondBot {
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 34px 20px 37px 58px;
    box-sizing: border-box;
    gap: 57px;
    line-height: normal;
    letter-spacing: normal;
    font-size: 24px;
  }
  @media screen and (max-width: 900px) {
    .bondBot {
      padding-left: 29px;
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 450px) {
    .forYourConscious {
      font-size: 19px;
    }
    .bondBot {
      gap: 28px;
    }
  }