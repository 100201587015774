
  /* PortfolioModal.css */

.custom-table .ant-table {
    font-size: 10px; /* Adjust the font size as needed */
  }
  
  /* Set a specific width for the Ticker column */
  .custom-table .ant-table-thead > tr > th[data-column-id='Ticker'],
  .custom-table .ant-table-tbody > tr > td[data-column-id='Ticker'] {
    width: 50px; /* Adjust the width as needed */
  }
  
  /* Set a specific width for other columns if needed */
  .custom-table .ant-table-thead > tr > th[data-column-id='Company'],
  .custom-table .ant-table-tbody > tr > td[data-column-id='Company'] {
    width: 100px; /* Adjust the width as needed */
  }
  
  /* Set a maximum length of text per column */
  .custom-table .ant-table-cell {
    max-width: 100px; /* Adjust the maximum width as needed */
    /* white-space: nowrap; */
    /* overflow: hidden; */
    text-overflow: ellipsis;
  }
  
  /* Set the total modal width */
.custom-modal .ant-modal-content {
    width: auto;
    max-width: 100%; /* Set the maximum width as needed */
    margin: 0 20px; /* Add horizontal margin to avoid edge sticking */
  }
  
  .custom-modal .ant-modal-body {
    overflow-x: auto;
  }

  .column-header {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .header-close {
    position: absolute;
    left: 50%;
    top: 50%;
    /* transform: translate(-50%, -50%) translateY(-320%); */
    transform: translate(0, -250%);
    cursor: pointer;
    color: #999;
    font-size: 10px;
  }
  
  .header-close:hover {
    color: #333;
  }
  .my-special-select .ant-select-selector::placeholder {
    font-size: 4px;
  }
  
  /* PortfolioModal.css */
.my-special-select .ant-select-item {
  font-size: 4px;
}

.my-special-select .ant-select-item-option-content {
  font-size: 8px;
}

.tableRow {
  font-family: Arial, sans-serif; /* Replace with your preferred font */
  font-size: 11px; /* Adjust font size as needed */
  line-height: 0.1; /* Adjust line height as needed */
}
.ant-table-thead > tr > th {
  font-family: Arial, sans-serif;
  font-size: 12px;
  line-height: 1.0;
}

.ant-table-expand-icon-col {
  width: 10px !important;
}
.ant-table .ant-table-expand-icon-col {
  width: 10px !important; 
}

