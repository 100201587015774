.alert-testing-modal {
    background-color: #f0f2f5; /* Light background for the modal */
}

.ant-table {
    border-radius: 8px; /* Rounded corners for the table */
    overflow: hidden; /* Prevent overflow */
}

.ant-table-thead > tr > th {
    background-color: #1890ff; /* Header background color */
    color: white; /* Header text color */
    font-weight: bold; /* Bold header text */
}

.ant-table-tbody > tr:hover {
    background-color: #e6f7ff; /* Light blue background on hover */
}

.alert-row {
    background-color: #fff7e6; /* Light orange for alert rows */
}

.clause-row {
    background-color: #e6fffb; /* Light teal for clause rows */
}

.ant-table-tbody > tr > td {
    transition: background-color 0.3s; /* Smooth transition for background color */
}