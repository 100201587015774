.small-table .ant-table-cell {
    font-size: 10px !important;
  }
  
  .bold-text {
    font-weight: bold;
    text-align: left;
  }
  
  .position-details-collapse .ant-collapse-header {
    font-weight: bold;
    font-size: 14px;
  }
  