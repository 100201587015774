/* WidgetAreaController.css */

.widget-title {
  z-index: 1; /* Lower z-index than the dots */
}
.EllipsisOutlined {
  position: absolute;
  top: 2px;
  right: 10px;
  z-index: 3; /* Ensure it's above other elements */
}

.widget-grid {
    display: grid;
    
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Creates a grid layout with a minimum column size of 250px */
    grid-template-rows: minmax(200px);  /* Rows with a minimum of 100px and maximum sized to fit the content */
    gap: 0.2rem; /* Space between grid items */
    padding: .2 rem; /* Padding around the grid */
  }
  
  .grid-spot {
    position: relative;
    padding: 0;
    padding-top: 1%;
    padding-right: 1%;
    /* border: 2px dashed #ccc; */
    border-radius: 1px; /* Rounded corners */
    display: flex;
    flex-direction: column; /* Un-comment this line */
    justify-content: space-between; /* Adjust space distribution */
    align-items: stretch; /* Stretch children width to fill the container */
    height: 200px; /* Fixed height for widgets */
    cursor: pointer;
    transition: background-color 0.3s;
    overflow: hidden; /* Prevents content from spilling out */
    background-color: #f0f0f0;
  }
  /* Apply a solid 1px border when widgetContentId is not empty */
.grid-spot.filled {
  border: None
  /* border: 0.01px solid #ccc; Change the border style to solid and set the color */
}

.grid-spot.empty {
  border: 0.1px dashed #ccc; /* Change the border style to solid and set the color */
}

  .grid-spot .ant-table {
    table-layout: fixed;
    width: 100%; /* Ensure table does not exceed the container width */
  }

  .grid-spot .ant-table tbody tr {
    min-height: 10px; /* Minimum height */
  }
  .grid-spot .ant-table tbody tr td {
    /* line-height: 0.5px; This should generally match the row height if you want a single line of text */
  }
  
  

  .grid-spot .ant-table th,
.grid-spot .ant-table td {
  padding: 4px; /* Reduce padding to fit content */
  font-size: 10px; /* Smaller font size to fit more content */
  word-wrap: break-word; /* Ensure long words do not cause overflow */
}

.grid-spot .ant-table thead th {
  line-height: 10px; /* Adjust the line height as needed */
  /* white-space: nowrap; This prevents text from wrapping and ensures the height is consistent */
  padding: 4px; /* Adjust padding to align with your design, smaller padding can help in tighter spaces */
}



  /* This media query will trigger when the screen width is 768px or less */
  @media (max-width: 768px) {
    .widget-grid {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* We keep the minimum width to maintain the widget size */
    }
  }
  
  /* This media query will trigger when the screen width is 480px or less */
  @media (max-width: 480px) {
    .widget-grid {
      grid-template-columns: 1fr; /* All items will stack into a single column */
    }
  }
  
  .widget-grid .grid-spot {
    /* Other styles */
    /* max-height: 150px; Adjust as needed */
    overflow: hidden; /* Enables scrolling if content is larger than the container */
  }
  
  .grid-spot h3 {
    font-size: 14px; /* Adjust the font size as needed */
    margin: 0; /* Remove default margin */
    padding: 4px; /* Add some padding if needed */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Add ellipsis if the text is too long */
    /* white-space: nowrap; */
    width: 100%; 
    margin-top: 0;
    margin-bottom: 0;
    
  }

  .widget-table-container {
    max-width: 100%; /* Ensures the container does not exceed the width of its parent */
    overflow: hidden;
    padding-left: 5px;/* Adds scrollbars if content is too wide */
  }
  
  .ant-table {
    width: 100%; /* Forces the table to fit its parent container */
    table-layout: fixed; /* Helps prevent cells from expanding too much */
  }

  /* Other CSS rules... */

.widget-placeholder {
  font-size: 14px; /* Adjust the font size as needed */
  margin: 0; /* Remove default margin */
  padding: 4px; /* Add some padding if needed */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis if the text is too long */
  white-space: nowrap;
  width: 100%; 
  margin-top: 0;
  margin-bottom: 0;
}

  
