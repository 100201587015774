
.small-font-table .ant-table {
  font-size: 10px;
}

.small-font-table .ant-table-cell {
  font-size: 10px;
}


.blur-text {
  filter: blur(4px);
  color: transparent;
  user-select: none;
  color: rgba(0, 0, 0, 0.5);
}

body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 20px;
}

ol, ul {
  padding-left: 30px;
}

.table-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
  margin-top: 20px;
}


.custom-header {
  cursor: pointer;
  font-weight: bold;
  font-size: 100%;
  animation: resizeText 1s steps(100) 1 forwards;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px; 
}

.custom-header:hover {
  color: #1677ff; /* Change this to the desired blue color */
}

.table-grid-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
}

.table-wrapper {
  margin-bottom: 0;
}

.ok-button {
  background-color: #1677ff;
  color: white;
}

.link-value {
  color: inherit;
  text-decoration: none;
}

.link-value:hover {
  color: #1677ff; /* Change this to the desired blue color */
}

.table-container {
  display: flex;
  justify-content: space-between;
}

.table-title {
  font-weight: bold;
  text-align: center;
  font-size: 12px;
}

.market-vectors-container .ant-table-title {
  font-weight: bold;
}

.market-vectors-container .ant-table {
  display: inline-table;
}

.market-vectors-container .ant-table:first-child {
  margin-right: 10px;
}

.custom-table .ant-table {
  font-size: calc(14px - 2pt);
}

.custom-table .ant-table-title {
  font-size: calc(14px - 2pt);
  font-weight: bold;
}

.custom-table .ant-table-thead > tr > th {
  font-size: calc(14px - 2pt);
}

.custom-table .ant-table-tbody > tr > td {
  font-size: calc(14px - 2pt);
}

.main-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;
}

.main-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.main-container > .market-vectors-container {
  display: flex;
}

.main-container h1 {
  margin-right: 20px;
}

.search-box-container {
  margin-right: 20px;
  height: 40px; /* Set the same height as the panel header */
  display: flex;
  align-items: center; /* Vertically center the content */
}



.table-grid-search-container {
  /* border: 1px solid red; for visibility of the container */
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: stretch; /* Stretch children to the width of the container */
  justify-content: flex-start; /* Align children to the start of the container vertically */
  width: 100%; /* Ensure the container takes up the full width */
  margin-top: 20px; /* Space from the top */
  margin-bottom: 500px; /* Space from the bottom */
}


.table-grid-search-container h1 {
  margin-right: 20px;
}


.tables-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between tables */
  justify-content: center; /* Centers the tables within the container */
  overflow-y: auto; /* Enables scrolling if content overflows vertically */
  max-height: 80vh; /* Limits height to keep tables within the viewport */
}

.tables-wrapper {
  flex: 1 1 300px; /* Adjusts each table’s width; min 300px, grows as needed */
  max-width: 100%;
  max-height: 40vh; /* Limits each table’s height */
  overflow-y: auto; /* Enables scrolling within each table */
}




.truncate-text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
}

.truncate-text.truncate-1,
.custom-header {
  max-width: 14ch; /* Set the max-width for the first column */
}

.truncate-text.truncate-2 {
  max-width: 20ch; /* Set the max-width for the second column */
}

.truncate-text.truncate-3 {
  max-width: 10ch; /* Set the max-width for the third column */
}

.custom-grid-table .ant-table-cell.truncate-text.truncate-1,
.custom-grid-table .ant-table-cell.custom-header {
  max-width: 14ch; /* Set the max-width for the first column */
}

.custom-grid-table .ant-table-cell.truncate-text.truncate-2 {
  max-width: 20ch; /* Set the max-width for the second column */
}

.custom-grid-table .ant-table-cell.truncate-text.truncate-3 {
  max-width: 10ch; /* Set the max-width for the third column */
}

.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-wrap:hover {
  color: #1677ff; /* Change this to the desired color */
}

.custom-tooltip {
  border-radius: 10px !important; /* Adjust the value according to your preference */
}

.margin-right-10 {
  margin-right: 10px;
}

.custom-element-height {
  height: 20px; /* Adjust the height as desired */
  display: flex;
  align-items: center; /* Vertically center the content */
}

.tables-container .ant-table-cell {
  font-size: 10px; /* Adjust the value as needed */
  min-width: 100px; /* Adjust as needed */
  margin: 5px; /* Space around each table */
}

.custom-table-row {
  font-size: 10px; /* Adjust the font size for table rows */
}

.modal-table .ant-table-thead > tr > th {
  font-size: 10px;
   z-index: 9999; /* Adjust the font size for table headers */
}

.modal-table .ant-table-tbody > tr > td {
  font-size: 10px;
   z-index: 9999; /* Adjust the font size for table rows */
}


.market-vectors-container .ant-table-cell {
  font-size: 10px;
}

.custom-header-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.drag-handle {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px;
  cursor: grab;
}

.dragging {
  cursor: grabbing !important; /* Change the cursor to a grabbing hand when dragging */
}

.responsive-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
}

@media (max-width: 768px) {
  .responsive-container {
    flex-direction: column;
  }
}

.portfolio-button {
  margin-left: 16px;
  margin-right: 20px;
}



/* Media query for narrow screens */
@media (max-width: 767px) {
  .search-box-container {
    flex-direction: column;
  }
}

/* Define a grid that has 1 column by default */
.checkbox-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

/* If the screen is at least 600px wide, make the grid have 2 columns */
@media (min-width: 600px) {
  .checkbox-grid {
    grid-template-columns: 1fr 1fr;
  }
}

/* If the screen is at least 900px wide, make the grid have 3 columns */
@media (min-width: 2000px) {
  .checkbox-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.preferences-checkbox {
  font-size: 10px;
  line-height: 1.0; 
}

.save-button {
  font-size: 10px;
}

.linkStyle {
  color: black; /* default color when not hovering */
  text-decoration: none; /* removes the underline */
  padding-left: 50px; /* adds space to the left */
}

.linkStyle:hover {
  color: blue; /* color when hovering */
}

.ant-tooltip-inner {
  background-color: #1677ff !important;
  color: white !important;  /* Set the color of the text to ensure visibility */
}

.ant-select-selection-item {
  font-size: 8px;
}

.ant-table-expand-icon-col {
  width: 10px !important;
}
.ant-table .ant-table-expand-icon-col {
  width: 10px !important; 
}

.hover-text-blue:hover {
  color:  #1677ff;
}

.esgmaintable-custom-font {
  font-family: 'Your Font Family', sans-serif;
  font-size: 10px;
  /* add other font properties as needed */
}

.cell-default,
.cell-green,
.cell-lightgreen,
.cell-yellow,
.cell-orange,
.cell-red {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 4px solid transparent; /* default to transparent border */
}

.cell-green {
  border-color: green;
}

.cell-lightgreen {
  border-color: lightgreen;
}

.cell-yellow {
  border-color: yellow;
}

.cell-orange {
  border-color: orange;
}

.cell-red {
  border-color: red;
}

.esg-custom-header {
  display: flex;
  flex-direction: column;
  height: 60px; /* Temporary, for testing */
  justify-content: space-between;
}

.esg-source-table {
  font-size: 10px;
  font-style: italic;
  text-align: center;
  margin-top: auto;
}



.top-right-button {
  display: flex;
  justify-content: flex-end; /* Aligns items to the right */
  gap: 16px; /* Creates space between the items */
}

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,400;0,700;0,800;1,400&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-inter: Inter;

  /* font sizes */
  --font-size-sm: 14px;
  --font-size-base: 16px;

  /* Colors */
  --color-black: #000;
}

.table-low-row {
  line-height: 0.1; /* Adjust the line height */
  font-family: Arial, sans-serif;
  font-size: 10px;
}

/* Bullet container for lines starting with '- ' */
.bullet-container {
  padding-left: 1.2em; /* Indentation for bullet */
  text-indent: -1.2em; /* Pull bullet to align with text */
  white-space: pre-wrap; /* Preserve formatting */
}

.bullet-container::before {
  content: "•"; /* Bullet symbol */
  color: black;
  margin-right: 0.5em; /* Space between bullet and text */
}

/* Styling for lines starting with '(initiated' or '(updated' */
.meta-info {
  color: gray; /* Grey text */
  font-style: italic; /* Italic font */
  font-size: 10px; /* Smaller font size */
  margin-left: 1.2em; /* Align with the bullet indentation */
  white-space: pre-wrap; /* Preserve formatting */
}




