.ticker-fundamentals {
    margin: 0 auto; /* Center the grid container */
    padding: 0; /* Remove any padding */
    max-width: 100%; /* Utilize the full width of the container */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Ensure flexible columns */
    gap: 3px; /* Minimize the gap between grid items */
    justify-content: center; /* Center the grid items */
}

.grid-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 5px; /* Reduce padding inside the blocks */
    text-align: left;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    width: auto; /* Allow dynamic adjustment based on grid-column */
    line-height: 1.2; /* Tighten the spacing within blocks */
}

.grid-item strong {
    display: block;
    margin-top: 0px;
    margin-bottom: 2px; /* Reduce spacing between block title and content */
    font-size: 12px;
}

.grid-item div {
    font-size: 12px;
    color: #555;
    margin: 0; /* Remove any margin within the div */
    line-height: 1.2; /* Tighten the spacing inside the divs */
}

@media (max-width: 1024px) {
    .ticker-fundamentals {
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); /* Adjust column size for tablets */
    }
}

@media (max-width: 768px) {
    .ticker-fundamentals {
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); /* Adjust column size for smaller screens */
    }
}

@media (max-width: 480px) {
    .ticker-fundamentals {
        grid-template-columns: 1fr; /* Single column layout for very small screens */
    }
}
