.preferencesContainer {
    margin: 10px;
  }
  
  .categoryTable {
    width: 100%;
    margin-bottom: 20px;
    border-collapse: collapse;
  }
  
  .categoryTable th,
  .categoryTable td {
    border: 1px solid #ccc;
    text-align: left;
    padding: 8px;
  }
  
  .categoryTable th {
    background-color: #f0f0f0;
  }

  .categoryTable td {
    text-align: center; /* Center align text and checkboxes horizontally */
    vertical-align: middle; /* Center align text and checkboxes vertically */
  }

  .categoryTable th:nth-child(2),
.categoryTable td:nth-child(2) {
  text-align: center; /* Center the text and checkbox */
}
  